<template>
    <loading :loading="loading" spinner-class="justify-center">
        <div style="margin-bottom: -15px;">
            <span class="text-theme mr-1 font-bold">{{ progress }}%</span>
            <span>Menuju Selesai</span>
        </div>
        <input type="range" :min="0" :max="100" v-model="progress" @change="onChange" @input="onInput">
    </loading>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        props: {
            value: {
                type: [Number, String],
                default: 0
            },
            skripsiId: {
                type: [Number, String],
                default: null
            },
            mentors: {
                type: Array,
                default: () => []
            }
        },
        data () {
            return {
                loading: false,
                localValue: 0,
                localValueChanged: false
            }
        },
        computed: {
            progress: {
                get () {
                    return this.localValueChanged ? this.localValue : this.value
                },
                set (value) {
                    //
                }
            },
            ...mapState('auth', { user: 'user' }),
            currentMentor () {
                return this.mentors.find(mentor => mentor.id === this.user.id)
            }
        },
        methods: {
            ...mapActions('skripsi', { updateSkripsi: 'update' }),
            onChange (e) {
                if (this.currentMentor) {
                    this.localValueChanged = true
                    this.localValue = e.target.value
                    this.loading = true
                    this.updateSkripsi({ id: this.skripsiId, data: { mentors: [{ ...this.currentMentor, progress: e.target.value }] } })
                        .finally(() => {
                            this.loading = false
                        })

                    this.$emit('change', e.target.value)
                }
            },
            onInput (e) {
                this.localValueChanged = true
                this.localValue = e.target.value
            }
        }
    }
</script>
